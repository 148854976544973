<!--
 * @Author: wxb
 * @Date: 2021-08-10 10:06:40
 * @LastEditTime: 2021-08-16 11:05:56
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Personal\myCollect.vue
-->
<template>
  <div class="containner">
    <div class="follow-filter">
      <div class="follow-filter-type">
        <ul class="filter-list">
          <li class="filter-item"
              v-for="(item,index) in collectionTypeLst"
              :key="index"
              :class="{active:active===index}"
              @click="handleClickType(item,index)">{{item.name}}</li>
        </ul>
      </div>
      <div class="follow-filter-search">
        <div class="project-search">
          <div class="project-search-input">
            <el-input class="project-search-field"
                      v-model="searchForm.collectionitemName"
                      placeholder="输入关键词搜索..."
                      clearable
                      @keyup.enter.native="searchFollow"></el-input>
            <button type="button"
                    class="project-search-btn"
                    @click="searchFollow">搜索</button>
          </div>
        </div>
      </div>
    </div>
    <div class="follow">
      <div class="follow-title">收藏列表</div>
      <div class="follow-list">
        <el-table :data="tableData"
                  style="width: 100%"
                  header-row-class-name="bg-f3">
          <template slot="empty">
            <el-empty :description="message4nodata"></el-empty>
          </template>
          <el-table-column prop="collectionitemName"
                           label="名称">
            <template slot-scope="scope">
              <el-button type="text"
                         size="small"
                         @click="handleClickItem(scope.row)"><span v-html="scope.row.collectionitemName"></span></el-button>
            </template>
          </el-table-column>
          <el-table-column prop="collectionType"
                           label="分类"
                           width="160">
            <template slot-scope="scope">
              <el-tag effect="dark"
                      v-if="scope.row.collectionType==='org'">机构</el-tag>
              <el-tag effect="dark"
                      v-else-if="scope.row.collectionType==='library' && scope.row.collectionSonType==='lays'">法律法规</el-tag>
              <el-tag effect="dark"
                      v-else-if="scope.row.collectionType==='library' && scope.row.collectionSonType==='strandard'">标准规范</el-tag>
              <el-tag effect="dark"
                      v-else-if="scope.row.collectionType==='library' && scope.row.collectionSonType==='atlas'">标准图集</el-tag>
              <el-tag effect="dark"
                      v-else-if="scope.row.collectionType==='library' && scope.row.collectionSonType==='data'">资料中心</el-tag>
              <el-tag effect="dark"
                      v-else-if="scope.row.collectionType==='document'">附件</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="crtTime"
                           label="收藏时间"
                           width="160">
          </el-table-column>
          <el-table-column label="操作"
                           width="120">
            <template slot-scope="scope">
              <el-button @click="handleClickCancle(scope.row)"
                         type="text"
                         size="small">取消收藏</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="content-page">
          <el-pagination background
                         layout="prev, slot, next"
                         :total="total"
                         :current-page.sync="currentPage"
                         @current-change="handleCurrentChange"
                         :hide-on-single-page="true">
            <span class="current-page">{{currentPage}}</span>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryCollection, collection } from '@/api/collect'
export default {
  name: 'myCollect',
  data() {
    return {
      active: 0,
      collectionTypeLst: [{
        name: '收藏的机构',
        code: 'org'
      }, {
        name: '收藏的文库',
        code: 'library'
      }, {
        name: '收藏的附件',
        code: 'document'
      }],
      searchForm: {
        collectionType: 'org',
        collectionitemName: '',
        start: 0,
        limit: 10
      },
      message4nodata: '哎呀，未查询到收藏的机构信息',
      tableData: [],
      total: 0,
      currentPage: 1
    }
  },
  methods: {
    // 点击分类筛选
    handleClickType(item, index) {
      this.active = index
      this.searchForm.collectionType = item.code
      this.searchForm.start = 0
      this.searchForm.collectionitemName = ''
      if (item.code === 'org') {
        this.message4nodata = '哎呀，未查询到收藏的机构信息'
      } else if (item.code === 'library') {
        this.message4nodata = '哎呀，未查询到收藏的文库信息'
      } else if (item.code === 'document') {
        this.message4nodata = '哎呀，未查询到收藏的附件信息'
      }
      this.getFollowList()
    },
    // 点击查询
    searchFollow() {
      this.currentPage = 1
      this.searchForm.start = 0
      this.getFollowList()
    },
    // 取消收藏
    handleClickCancle(row) {
      this.$confirm('确认取消收藏?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          collectionitemId: row.collectionitemId,
          collectionType: row.collectionType,
          collectionSonType: row.collectionSonType,
          collectionitemName: row.collectionitemName,
          action: 'cancle'
        }
        this.handleFollowEvent(params)
      })
    },
    handleFollowEvent(params) {
      collection(params).then(res => {
        if (res.ok) {
          this.$message({
            message: '取消收藏成功',
            type: 'success'
          })
          this.getFollowList()
        } else {
          this.$message({
            message: res,
            type: 'warning'
          })
        }
      }).catch(err => { console.log(err) })
    },
    // 分页处理
    handleCurrentChange(val) {
      this.searchForm.start = (val - 1) * this.searchForm.limit
      this.getFollowList()
    },
    getFollowList() {
      queryCollection(this.searchForm).then(res => {
        if (res.ok) {
          this.tableData = res.lst
          this.total = res.cnt
        }
      }).catch(err => { console.log(err) })
    },
    handleClickItem(row) {
      const id = row.collectionitemId
      if (row.collectionType === 'org') {
        const routeData = this.$router.resolve({
          name: 'orgDetail',
          params: {
            id
          }
        })
        window.open(routeData.href, '_blank')
      } else if (row.collectionType === 'library') {
        let type = '1'
        const collectionSonType = row.collectionSonType
        if (collectionSonType === 'strandard') {
          type = '1'
        } else if (collectionSonType === 'atlas') {
          type = '2'
        } else if (collectionSonType === 'lays') {
          type = '3'
        } else if (collectionSonType === 'data') {
          type = '4'
        }
        const routeData = this.$router.resolve({
          name: 'libaryDetail',
          params: {
            id,
            type
          }
        })
        window.open(routeData.href, '_blank')
      } else if (row.collectionType === 'document') {
        const routeData = this.$router.resolve({
          name: 'preview',
          params: {
            filekey: id
          }
        })
        window.open(routeData.href, '_blank')
      }
    }
  },
  mounted() {
    this.getFollowList()
  }
}
</script>

<style lang="scss" scoped>
.containner {
  width: 100%;
  padding-left: 2rem;
}

.follow-filter {
  display: flex;
  align-items: center;
  &-type {
    flex: 1;
    .filter {
      &-item {
        position: relative;
        float: left;
        font-size: 14px;
        height: 22px;
        line-height: 22px;
        margin-right: 20px;
        cursor: pointer;
        &.active {
          border-bottom: 2px solid #0073bc;
          font-weight: 700;
          color: #0073bc;
        }
      }
    }
  }

  &-search {
    width: 33rem;
    // 搜索框
    .project {
      &-search {
        background: #ffffff;
        &-input {
          width: 330px;
          height: 34px;
          display: flex;
          margin: 0 auto;
        }

        &-field {
          flex: 1;
          border: solid 2px #398ce2;
          background-color: #ffffff;
          font-size: 12px;
          font-weight: normal;
          letter-spacing: 0px;
          color: #898a8c;
          /deep/ .el-input__inner {
            border-radius: 0;
            height: 30px;
            line-height: 30px;
          }
          /deep/ .el-input__suffix {
            top: -4px;
          }
        }

        &-btn {
          right: 0;
          width: 56px;
          height: 34px;
          background-color: #398ce2;
          border: 1px solid #398ce2;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0px;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}

.follow {
  margin-top: 2rem;
  &-title {
    padding: 1rem 0rem;
    font-size: 1.6rem;
    border-bottom: 0.1rem solid #dddddd;
  }

  &-list {
    margin-top: 1rem;

    /deep/ .bg-f3 {
      background: #f3f3f3;
      th,
      tr {
        background: #f3f3f3 !important;
      }

      .cell {
        font-size: 1.6rem;
        color: rgba(0, 0, 0, 0.65);
        line-height: 1.6rem;
        font-weight: 400;
      }
    }
    .content-page {
      margin-top: 10px;
      text-align: right;
    }
  }
}
</style>
